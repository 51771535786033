import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyIFrame = lazy(
  () => import("./i-frame-B8CRDd13.js").then((module) => ({
    default: module.IFrame
  }))
);
function IFrame({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyIFrame, { ...props });
}
const useIFrameContent = () => {
  return useMemo(
    () => ({
      "i-frame": {
        component: IFrame
      }
    }),
    []
  );
};
export {
  LazyIFrame,
  useIFrameContent
};
